import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { actionsNotifications } from 'src/app/_BLL/notifications/slice';
import { createAppAsyncThunk } from 'src/app/redux/createAction';
import { SearchResponseCompany } from 'src/pages/MainPage/api/mainSearch/types';
import { apiRequest } from 'src/shared/api/api';
import { createDebouncedAsyncThunk } from 'src/shared/api/createDebouncedAsyncThunk';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import {
	AbsoluteBenchmarkScore,
	Distribution,
	EditMeta,
	ExposureDistributionItem,
	ExposureItem,
	InfoItem,
	Portfolio,
	QuartileDistributionItem,
} from 'src/shared/types/portfolio_funds/portfolio_funds';
import { IOverview } from 'src/entities/portfolio_funds/Overview/types';
import { FundsLookupREQ_BODY, PortfolioCreateEditREQ } from './reqTypes';
import { FundsLookupRES } from './resTypes';
import { CONTRAST_PALETTE } from 'src/shared/consts/_colors/contrastColors';

const NAME = UrlAPI.funds;

export const getEditMetadata = createAppAsyncThunk(`${NAME}/getEditMetadata`, async (_: void, thunkAPI) => {
	return await apiRequest.getRequest<EditMeta>({
		url: `${NAME}/editMetadata`,
		thunkAPI,
	});
});

export const getOverview = createAppAsyncThunk(`${NAME}/getOverview`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<IOverview>({
		url: `${NAME}/overview`,
		params,
		thunkAPI,
	});
});

export const getPortfolio = createAppAsyncThunk(`${NAME}/getPortfolio`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<Portfolio>({
		url: `${NAME}/portfolio`,
		params,
		thunkAPI,
	});
});

export const savePortfolio = createAppAsyncThunk(`${NAME}/postPortfolio`, async (arg: PortfolioCreateEditREQ, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.postRequest<Portfolio>({
		url: `${NAME}/portfolio`,
		payload,
		thunkAPI,
	});
});

export const getQuartileDistribution = createAppAsyncThunk(`${NAME}/getQuartileDistribution`, async (arg: { params: { id: number; dimensionId: string } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<QuartileDistributionItem>({
		url: `${NAME}/quartileDistribution`,
		params,
		thunkAPI,
	});
});

export const getRegionalDistribution = createAppAsyncThunk(`${NAME}/getRegionalDistribution`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<Distribution[]>({
		url: `${NAME}/regionalDistribution`,
		params,
		thunkAPI,
	});
});

export const getSectoralDistribution = createAppAsyncThunk(`${NAME}/getSectoralDistribution`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<Distribution[]>({
		url: `${NAME}/sectoralDistribution`,
		params,
		thunkAPI,
	});
});

export const getAbsoluteVsBenchmarkScores = createAppAsyncThunk(`${NAME}/absoluteVsBenchmarkScores`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<AbsoluteBenchmarkScore[]>({
		url: `${NAME}/absoluteVsBenchmarkScores`,
		params,
		thunkAPI,
	});
});

export const getExposure = createAppAsyncThunk(`${NAME}/getExposure`, async (arg: { params: { id: number; belowBenchmark: boolean } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<ExposureItem[]>({
		url: `${NAME}/exposure`,
		params,
		thunkAPI,
	});
});

export const getExposureDistribution = createAppAsyncThunk(
	`${NAME}/getExposureDistribution`,
	async (arg: { params: { id: number; belowBenchmark: boolean; dataPointId: number; dimensionId: string } }, thunkAPI) => {
		const { params } = arg;

		return await apiRequest.getRequest<ExposureDistributionItem[]>({
			url: `${NAME}/exposureDistribution`,
			params,
			thunkAPI,
		});
	},
);

export const fundsLookup = createDebouncedAsyncThunk(
	`${NAME}/lookup`,
	async (payload: FundsLookupREQ_BODY, thunkAPI) => {
		const { signal, dispatch } = thunkAPI;

		if (payload.searchPrefix !== '') {
			const res = await apiRequest.postRequest<FundsLookupRES>({
				url: `${UrlAPI.funds}/lookup`,
				payload,
				thunkAPI,
				signal,
			});

			if (res.funds.length === 0) {
				dispatch(
					actionsNotifications.addNotification({
						type: 'info',
						message: 'Nothing found',
					}),
				);
			}

			return res;
		} else {
			return {
				funds: [],
				// pagination: {
				// 	pageSize: 10,
				// 	pageIndex: 0,
				// 	pageCount: 0,
				// 	count: 0,
				// },
			};
		}
	},
	500,
);

interface State {
	editMeta: EditMeta | null;
	funds: SearchResponseCompany[];
	overview: IOverview | null;
	below: boolean;
	portfolio: Portfolio | null;
	dimensionsList: InfoItem[];
	quartileDistribution: QuartileDistributionItem | null;
	regionalDistribution: {
		data: Distribution[];
		status: RequestStatus;
	};
	sectoralDistribution: {
		data: Distribution[];
		status: RequestStatus;
	};
	absoluteBenchmarkScores: {
		data: AbsoluteBenchmarkScore[];
		status: RequestStatus;
	};
	exposure: {
		data: ExposureItem[];
		status: RequestStatus;
	};
	exposureDistribution: {
		[key: string]: {
			data: ExposureDistributionItem[];
			status: RequestStatus;
		};
	};
	status: RequestStatus;
}

export const initialState: State = {
	editMeta: null,
	funds: [],
	overview: null,
	below: true,
	portfolio: null,
	dimensionsList: [],
	quartileDistribution: null,
	regionalDistribution: {
		data: [],
		status: RequestStatus.still,
	},
	sectoralDistribution: {
		data: [],
		status: RequestStatus.still,
	},
	absoluteBenchmarkScores: {
		data: [],
		status: RequestStatus.still,
	},
	exposure: {
		data: [],
		status: RequestStatus.still,
	},
	exposureDistribution: {},
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		clearFunds: (state) => {
			state.funds = initialState.funds;
			state.status = initialState.status;
		},
		setBelow: (state, action: PayloadAction<boolean>) => {
			state.below = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getEditMetadata.pending, (state) => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getEditMetadata.fulfilled, (state, action) => {
			const benchmarks = action.payload.benchmarks.map((benchmark, index) => ({
				...benchmark,
				color: CONTRAST_PALETTE[index],
			}));

			// Placing Median between 25 and 75.
			for (let i = 0; i < benchmarks.length; i++) {
				if (benchmarks[i].name.includes('25')) {
					const medianBenchmark = benchmarks[i - 1];

					benchmarks[i - 1] = benchmarks[i];
					benchmarks[i] = medianBenchmark;
				}
			}

			state.editMeta = {
				...action.payload,
				benchmarks,
				indexes: action.payload.indexes.map((indexScore, index) => ({
					...indexScore,
					color: CONTRAST_PALETTE[action.payload.benchmarks.length + index],
				})),
			};
			state.dimensionsList = action.payload.dimensionGroups.map((dimensionGroup) => dimensionGroup.dimensions.map((dimension) => dimension)).flat();
			state.status = RequestStatus.still;
		});
		builder.addCase(getEditMetadata.rejected, (state) => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getOverview.pending, (state) => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getOverview.fulfilled, (state, action) => {
			state.overview = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getOverview.rejected, (state) => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getPortfolio.pending, (state) => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getPortfolio.fulfilled, (state, action) => {
			state.portfolio = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getPortfolio.rejected, (state) => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(savePortfolio.pending, (state) => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(savePortfolio.fulfilled, (state) => {
			state.status = RequestStatus.still;
		});
		builder.addCase(savePortfolio.rejected, (state) => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getQuartileDistribution.pending, (state) => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getQuartileDistribution.fulfilled, (state, action) => {
			state.quartileDistribution = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getQuartileDistribution.rejected, (state) => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getRegionalDistribution.pending, (state) => {
			state.regionalDistribution.status = RequestStatus.loading;
		});
		builder.addCase(getRegionalDistribution.fulfilled, (state, action) => {
			state.regionalDistribution.data = action.payload;
			state.regionalDistribution.status = RequestStatus.still;
		});
		builder.addCase(getRegionalDistribution.rejected, (state) => {
			state.regionalDistribution.status = RequestStatus.failed;
		});

		builder.addCase(getSectoralDistribution.pending, (state) => {
			state.sectoralDistribution.status = RequestStatus.loading;
		});
		builder.addCase(getSectoralDistribution.fulfilled, (state, action) => {
			state.sectoralDistribution.data = action.payload;
			state.sectoralDistribution.status = RequestStatus.still;
		});
		builder.addCase(getSectoralDistribution.rejected, (state) => {
			state.sectoralDistribution.status = RequestStatus.failed;
		});

		builder.addCase(getAbsoluteVsBenchmarkScores.pending, (state) => {
			state.absoluteBenchmarkScores.status = RequestStatus.loading;
		});
		builder.addCase(getAbsoluteVsBenchmarkScores.fulfilled, (state, action) => {
			state.absoluteBenchmarkScores.data = action.payload;
			state.absoluteBenchmarkScores.status = RequestStatus.still;
		});
		builder.addCase(getAbsoluteVsBenchmarkScores.rejected, (state) => {
			state.absoluteBenchmarkScores.status = RequestStatus.failed;
		});

		builder.addCase(getExposure.pending, (state) => {
			state.exposure.status = RequestStatus.loading;
		});
		builder.addCase(getExposure.fulfilled, (state, action) => {
			state.exposure.data = action.payload;
			state.exposure.status = RequestStatus.still;
		});
		builder.addCase(getExposure.rejected, (state) => {
			state.exposure.status = RequestStatus.failed;
		});

		builder.addCase(getExposureDistribution.pending, (state, action) => {
			const { dimensionId, dataPointId } = action.meta.arg.params;

			state.exposureDistribution[`${dimensionId}_${dataPointId}`] = {
				data: [],
				status: RequestStatus.loading,
			};
		});
		builder.addCase(getExposureDistribution.fulfilled, (state, action) => {
			const { dimensionId, dataPointId } = action.meta.arg.params;

			state.exposureDistribution[`${dimensionId}_${dataPointId}`] = {
				data: action.payload,
				status: RequestStatus.failed,
			};
		});
		builder.addCase(getExposureDistribution.rejected, (state, action) => {
			const { dimensionId, dataPointId } = action.meta.arg.params;

			state.exposureDistribution[`${dimensionId}_${dataPointId}`] = {
				data: [],
				status: RequestStatus.failed,
			};
		});

		builder.addCase(fundsLookup.pending, (state, action) => {
			const searchPrefix = action.meta.arg.searchPrefix;
			state.status = searchPrefix === '' ? RequestStatus.still : RequestStatus.loading;
		});
		builder.addCase(fundsLookup.fulfilled, (state, action) => {
			state.funds = [...action.payload.funds];
			// state.data.pagination = action.payload.pagination;
			state.status = RequestStatus.still;
		});
		builder.addCase(fundsLookup.rejected, (state, action) => {
			if (!action.meta.aborted) {
				state.status = RequestStatus.failed;
			}
		});
	},
});

export const actionsFunds = {
	...slice.actions,
	getEditMetadata,
	getOverview,
	getPortfolio,
	savePortfolio,
	getQuartileDistribution,
	getRegionalDistribution,
	getSectoralDistribution,
	getAbsoluteVsBenchmarkScores,
	getExposure,
	getExposureDistribution,
	fundsLookup,
};
