import { FC, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useGetDataPoints } from 'src/app/indexedDB/dataPointsDB/hooks/useGetDataPoints';
import { useGetDataPointTree } from 'src/app/indexedDB/dataPointsTreeDB/hooks/useGetDataPointTree';
import { useGetDictionaries } from 'src/app/indexedDB/dictionaryDB/hooks/useGetDictionaries';
import { useAppSelector } from 'src/app/redux/createAction';
import { ScrollToTop } from 'src/app/router/ui/ScrollToTop';
import { BenchmarkPageLazy } from 'src/pages/BenchmarkPage';
import { DatabaseNewPageLazy } from 'src/pages/DatabaseNewPage';
import { DatabasePageLazy } from 'src/pages/DatabasePage';
import { FundPageLazy, FundsConfigPageLazy } from 'src/pages/funds_pages';
import { FundPageNewLazy } from 'src/pages/funds_pages_new';
import { MainSearchPageLazy } from 'src/pages/MainPage';
import { MainSearchResultsPageLazy } from 'src/pages/MainSearchResultsPage';
import { NotFoundPage } from 'src/pages/NotFoundPage/NotFoundPage';
import { PortfolioConfigPageLazy, PortfolioHelpPageLazy, PortfolioListPageLazy, PortfolioPageLazy, PortfolioUploadPageLazy } from 'src/pages/portfolio_analyzer_pages';
import { ResetPasswordPage } from 'src/pages/ResetPasswordPage';
import { SignInPage } from 'src/pages/SignInPage';
import { WelcomePageLazy } from 'src/pages/WelcomePage';
import { renderRoute } from '../lib/renderRoute';

export const routerPath = {
	mainWelcome: {
		page: '/welcome' as const,
	},
	mainSearch: {
		page: '/main' as const,
	},
	mainSearchResults: {
		page: '/search_results' as const,
	},
	database: {
		page: '/database' as const,
	},
	databaseNew: {
		page: '/database_new' as const,
	},
	benchmark: {
		page: '/benchmark' as const,
	},
	portfolio: {
		page: '/portfolio_analyzer' as const,
		config: '/config' as const,
		help: '/help' as const,
		portfolio: '/portfolio' as const,
		portfolioList: '/portfolio_list' as const,
		upload: '/upload' as const,
	},
	funds: {
		page: '/funds' as const,
		config: '/config' as const,
		fund: '/fund' as const,
	},
	fundsNew: {
		page: '/funds_new' as const,
		fund: '/fund' as const,
	},
};

export const AppRouter: FC = () => {
	const userData = useAppSelector(state => state.auth.userData);

	const dictionaries = useGetDictionaries();
	const dataPoints = useGetDataPoints();
	const dataPointTree = useGetDataPointTree();

	const coreDataExists = dictionaries && dataPoints && dataPointTree;
	const coreData = coreDataExists
		? {
				dictionaries,
				dataPoints,
				dataPointTree,
			}
		: null;

	const tempUserName = localStorage.getItem('temp_user_name');

	// * Render
	return (
		<BrowserRouter>
			<ScrollToTop />

			<Suspense fallback={<div>Loading...</div>}>
				<Routes>
					<Route
						path="/"
						element={
							userData ? (
								<Navigate
									to={routerPath.mainWelcome.page}
									replace
								/>
							) : (
								<SignInPage />
							)
						}
					/>
					<Route
						path="/login/password"
						element={
							tempUserName ? (
								<ResetPasswordPage />
							) : (
								<Navigate
									to="/"
									replace
								/>
							)
						}
					/>
					{renderRoute(routerPath.mainWelcome.page, <WelcomePageLazy />, userData, coreData)}
					{renderRoute(routerPath.mainSearch.page, <MainSearchPageLazy />, userData, coreData)}
					{renderRoute(`${routerPath.mainSearchResults.page}/:tab`, <MainSearchResultsPageLazy />, userData, coreData)}

					{renderRoute(`${routerPath.database.page}/company/:id/:menuId`, <DatabaseNewPageLazy />, userData, coreData)}
					{renderRoute(`${routerPath.database.page}/:tab/:id/:menuId`, <DatabasePageLazy />, userData, coreData)}
					{renderRoute(`${routerPath.benchmark.page}`, <BenchmarkPageLazy />, userData, coreData)}

					{/* Portfolio analyzer */}
					{renderRoute(`${routerPath.portfolio.page}${routerPath.portfolio.config}/:id`, <PortfolioConfigPageLazy />, userData, coreData)}
					{renderRoute(`${routerPath.portfolio.page}${routerPath.portfolio.help}`, <PortfolioHelpPageLazy />, userData, coreData)}
					{renderRoute(`${routerPath.portfolio.page}${routerPath.portfolio.portfolio}/:id`, <PortfolioPageLazy />, userData, coreData)}
					{renderRoute(`${routerPath.portfolio.page}${routerPath.portfolio.portfolioList}`, <PortfolioListPageLazy />, userData, coreData)}
					{renderRoute(`${routerPath.portfolio.page}${routerPath.portfolio.upload}`, <PortfolioUploadPageLazy />, userData, coreData)}
					{/* Funds */}
					{renderRoute(`${routerPath.funds.page}${routerPath.funds.fund}/:id`, <FundPageLazy />, userData, coreData)}
					{renderRoute(`${routerPath.funds.page}${routerPath.funds.config}/:id`, <FundsConfigPageLazy />, userData, coreData)}
					{renderRoute(`${routerPath.fundsNew.page}${routerPath.fundsNew.fund}/:id`, <FundPageNewLazy />, userData, coreData)}
					<Route
						path="*"
						element={<NotFoundPage />}
					/>
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
};
